<template>
  <div>
    <a-tabs v-model="searchForm.category_id" type="card" @change="getMenuList()">
      <a-tab-pane :key="0" tab="平台系统"></a-tab-pane>
      <a-tab-pane :key="1" tab="后台系统"></a-tab-pane>
    </a-tabs>
    <a-card>
      <template slot="title">
        <span class="table-title">{{searchForm.category_id == 1 ? '后台' : '平台'}}菜单管理</span>
        <a-button type="primary" icon="plus" @click="showModal('add')">新增</a-button>
      </template>
      <a-table
        size="middle"
        :loading="loading"
        row-key="id"
        :indent-size="20"
        :columns="columns"
        :data-source="datalist"
        :expanded-row-keys="expandRows"
        :pagination="false"
        @expand="expandChange">
        <template slot="icon" slot-scope="text">
          <a-icon v-if="text" :type="text" />
        </template>
        <template slot="type" slot-scope="text">
          <a-tag v-if="text == 0" color="#f50">导航</a-tag>
          <a-tag  v-else-if="text == 1" color="#87d068">页面</a-tag>
          <a-tag v-else color="#108ee9">按钮</a-tag>
        </template>
        <!-- 功能 -->
        <template slot="function_list" slot-scope="text">
          <a-tag color="purple" v-for="func in text" :key="func.id" class="tag-func">
            {{func.name}}
          </a-tag>
        </template>
        <!-- 操作 -->
        <template v-if="record.type != 2" slot="action" slot-scope="record">
          <a-button v-if="record.type != 1" type="link" @click="showModal('add', record)">新增</a-button>
          <a-button type="link" @click="showModal('edit', record)">编辑</a-button>
          <a-popconfirm
            title="您确认要删除吗？"
            @confirm="del(record.id)">
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
    <!-- 弹框 -->
    <a-modal
      :title="modalType == 'add' ? '新增菜单' : '修改菜单'"
      :width="850"
      :visible="dialog"
      @ok="modalConfirm"
      @cancel="() => {
        dialog = false
        $refs.modalForm.resetFields()
      }">
      <a-spin :spinning="spinning">
        <a-form-model
          ref="modalForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 14 }">
          <a-form-model-item v-show="false"  prop="pid">
            <a-input v-model="form.pid" disabled></a-input>
          </a-form-model-item>
          <a-form-model-item label="上级菜单" prop="parent_name">
            <a-input v-model="form.parent_name" disabled></a-input>
          </a-form-model-item>
          <a-form-model-item label="菜单名称" prop="name">
            <a-input v-model="form.name" placeholder="用于显示在左侧菜单栏"></a-input>
          </a-form-model-item>
          <a-row>
            <a-col :span="12">
              <a-form-model-item 
                label="菜单图标" 
                prop="icon"
                :label-col="{ span: 12 }" 
                :wrapper-col="{ span: 12 }">
                <a-input v-model="form.icon" placeholder="菜单小图标" class="input-icon"></a-input>
                <a-icon v-if="form.icon" :type="form.icon"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item 
                label="排序" 
                prop="sort"
                :label-col="{ span: 6 }" 
                :wrapper-col="{ span: 10 }">
                <a-input v-model="form.sort"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item 
                label="菜单类型" 
                prop="type"
                :label-col="{ span: 12 }" 
                :wrapper-col="{ span: 12 }">
                <a-radio-group v-model="form.type">
                  <a-radio :value="0">导航</a-radio>
                  <a-radio :value="1">页面</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item 
                label="状态" 
                prop="status"
                :label-col="{ span: 6 }" 
                :wrapper-col="{ span: 10 }">
                <a-switch v-model="form.status"></a-switch>
              </a-form-model-item>
            </a-col>
          </a-row>
          
          <div v-show="form.type == 1">
            <a-form-model-item label="跳转路由" prop="routes">
              <a-input v-model="form.url" placeholder="用于路由跳转"></a-input>
            </a-form-model-item>
            <a-form-model-item label="显示组件" prop="components">
              <a-input v-model="form.components" placeholder="用于配置显示的页面"></a-input>
            </a-form-model-item>
            <a-form-model-item label="控制器" prop="url">
              <a-input v-model="form.routes" placeholder="请求接口"></a-input>
            </a-form-model-item>
            <a-divider orientation="left">功能</a-divider>
            <div class="controller">
              <a-button type="danger" icon="plus" size="small" @click="addFunc()">添加功能</a-button>
              <span class="tip">
                * 默认配置新增，编辑，删除，查看功能，可根据实际情况进行调整
              </span>
            </div>
            <a-table 
              bordered
              size="middle"
              :row-key="(record, index) => index"
              :columns="modal_columns"
              :data-source="modal_datalist"
              :pagination="false">
              <template
                v-for="col in ['name', 'web_url', 'routes', 'components', 'url']"
                :slot="col"
                slot-scope="text, record, index">
                <div :key="col">
                  <a-input v-model.trim="record[col]"></a-input>
                </div>
              </template>
              <!-- 操作 -->
              <template slot="action" slot-scope="text, record, index">
                <a-button type="link" @click="delFunc(index)">删除</a-button>
              </template>
            </a-table>
          </div>
        </a-form-model>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      searchForm: {
        category_id: 0,
        page: 1,
        list_rows: 30
      },
      columns: [
        { title: '名称', key: 'name', dataIndex: 'name'},
        // { title: '图标', key: 'icon', dataIndex: 'icon', align: 'center', scopedSlots: { customRender: 'icon'}, width: 80 },
        { title: '路由', key: 'url', dataIndex: 'url', ellipsis: true },
        { title: '组件', key: 'components', dataIndex: 'components', ellipsis: true },
        { title: '控制器', key: 'routes', dataIndex: 'routes', ellipsis: true },
        { title: '类型', key: 'type', dataIndex: 'type', align: 'center', scopedSlots: { customRender: 'type'}, width: 80 },
        { title: '排序', key: 'sort', dataIndex: 'sort', align: 'center', width: 65 },
        { title: '功能', key: 'function_list', dataIndex: 'function_list', align: 'center', scopedSlots: { customRender: 'function_list'} },
        { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action'} }
      ],
      datalist: [],
      expandRows: [],
      // 弹框
      dialog: false,
      confirmLoading: false,
      modalType: 'add',
      spinning: false,
      init_form: {},
      form: {
        category_id: 0,
        pid: 0,
        parent_name: '根目录',
        name: '',
        icon: '',
        sort: '',
        type: 0,
        status: true,
        routes: '',
        components: '',
        url: '',
        function_list: ''
      },
      rules: {},
      modal_columns: [
        { title: '功能', key: 'name', dataIndex: 'name', width: 100, scopedSlots: { customRender: 'name'}},
        { title: '标识', key: 'web_url', dataIndex: 'web_url', width: 100, scopedSlots: { customRender: 'web_url'}},
        { title: '跳转路由', key: 'routes', dataIndex: 'routes', scopedSlots: { customRender: 'routes'}},
        { title: '相关页面', key: 'components', dataIndex: 'components', scopedSlots: { customRender: 'components'}},
        { title: '控制器', key: 'url', dataIndex: 'url', scopedSlots: { customRender: 'url'}},
        { title: '操作', key: 'action', width: 80, align: 'center', scopedSlots: { customRender: 'action'}},
      ],
      init_modalList: [],
      modal_datalist: [
        { name: '新增', web_url: 'add', routes: '', components: '', url: '' },
        { name: '编辑', web_url: 'edit', routes: '', components: '', url: '' },
        { name: '查看', web_url: 'info', routes: '', components: '', url: '' },
        { name: '删除', web_url: 'del', routes: '', components: '', url: '' }
      ]
    }
  },
  mounted () {
    this.init_modalList = this.modal_datalist.map(item => ({...item}))
    this.init_form = {...this.form}
    this.getMenuList()
  },
  methods: {
    getMenuList () {
      this.loading = true
      this.api.getMenuList(this.searchForm).then(res => {
        this.datalist = res.data.data
        this.expandRows = this.datalist.map(item => item.id)
        this.loading = false 
      }).catch(() => {
        this.loading = false 
      })
    },
    showModal (type, record) {
      this.dialog = true
      this.modalType = type
      this.form = this.init_form
      this.form.category_id = this.searchForm.category_id
      this.modal_datalist = this.init_modalList.map(item => ({...item}))
      this.$nextTick(() => {
        this.$refs.modalForm.resetFields()
        if (record) {
          this.getInfo(record.id)
        }
      })
    },
    // 新增功能
    addFunc () {
      let item = { name: '', web_url: '', routes: '', components: '', url: '' }
      this.modal_datalist.push(item)
    },
    delFunc (index) {
      this.modal_datalist.splice(index, 1)
    },
    modalConfirm () {
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          let param = {...this.form}
          param.status = this.form.status ? 1 : 0
          if (this.form.type == 1) {
            param.function_list = this.modal_datalist.length != 0 ? JSON.stringify(this.modal_datalist) : ''
          }
          this.confirmLoading = true
          if (this.modalType == 'add') {
            this.add(param)
          } else {
            this.edit(param)
          }
        } else {
          return false
        }
      })
    },
    add (param) {
      this.api.addMenu(param).then(res => {
        this.$message.success(res.data.msg)
        this.confirmLoading = false 
        this.dialog = false
        this.getMenuList()
      }).catch(err => {
        this.confirmLoading = false 
        this.dialog = false
      })
    },
    getInfo (menuId) {
      this.spinning = true
      this.api.getMenuInfo({id: menuId}).then(res => {
        this.spinning = false
        let info = res.data.data 
        if (this.modalType == 'add') {
          this.form.pid = info.id
          this.form.parent_name = info.name
          this.form.type = info.type == 0 ? 1 : ''
        } else {
          this.form = {...info}
          this.form.parent_name = info.pid == 0? '根目录' : info.pname
          this.form.status = info.status == 1 ? true : false
          this.modal_datalist = info.function_list
        }
      }).catch(err => {
        this.spinning = false
      })
    },
    edit (param) {
      this.api.editMenu(param).then(res => {
        this.$message.success(res.data.msg)
        this.confirmLoading = false 
        this.dialog = false
        this.getMenuList()
      }).catch(err => {
        this.confirmLoading = false 
        this.dialog = false
      })
    },
    del (menuId) {
      this.api.delMenu({id: menuId}).then(res => {
        this.$message.success(res.data.msg)
        this.getMenuList()
      })
    },
    expandChange (expanded, row) {
      if (expanded) {
        this.expandRows.push(row.id)
      } else {
        let index = this.expandRows.findIndex(item => item == row.id)
        this.expandRows.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.controller {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  .tip {
    text-align: right;
    font-size: 12px;
    line-height: 24px;
    color: #9a9a9a;
    transform: scale(0.95);
  }
}
.ant-card {
  /deep/.ant-table-row-level-1 {
    background-color: #e2eefc;
    td {
      border-color: #fff;
    }
  }
}
.ant-tag {
  margin: 0
}
.ant-modal-body /deep/{
  .ant-btn {
    margin: 0;
  }
  .input-icon {
    width: 60%;
    margin-right: 10px;
  }
}
.tag-func {
  margin-right: 3px;
  margin-bottom: 5px;
}
</style>